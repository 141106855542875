<template>
    <section>
        <modal ref="cambiarEstadoMaterial" titulo="Crear orden de compra" tamano="modal-lg" adicional="Continuar" @adicional="continuarCrearOrden ">
            <div class="container">
                <div class="row f-14">
                    <div class="col-12">
                        <p class="input-label-top">Obra</p>
                        <el-select v-model="valueSelect" placeholder="Seleccionar obra" size="small" class="w-100">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </div>
                </div>
                <div class="row f-14 my-4">
                    <div class="col-6">
                        <p class="input-label-top">Proveedor</p>
                        <el-select v-model="valueSelect" placeholder="Seleccionar proveedor" size="small" class="w-100">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </div>
                    <div class="col-6">
                        <p class="input-label-top">Fecha de entrega</p>
                        <el-date-picker v-model="datePicker" type="date" class="w-100" size="small" placeholder="Seleccionar una fecha" />
                    </div>
                </div>
                <div class="row f-14 my-4">
                    <div class="col-12">
                        <p class="input-label-top">Destino</p>
                        <div>
                            <el-radio v-model="radio" label="1" class="f-12 f-300">Talleres</el-radio>
                            <el-radio v-model="radio" label="2" class="f-12 f-300">Clientes</el-radio>
                            <el-radio v-model="radio" label="3" class="f-12 f-300">Otros</el-radio>
                        </div>
                    </div>
                </div>
                <div class="row f-14 my-4">
                    <div class="col-6">
                        <p class="input-label-top">Servicio</p>
                        <el-select v-model="valueSelect" placeholder="Seleccionar servicio" size="small" class="w-100">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </div>
                </div>
                <div class="row f-14 my-4">
                    <p class="col-12 f-12 f-700">O crear servicio</p>
                </div>
                <div class="row f-14">
                    <div class="col-12">
                        <p class="input-label-top">Taller</p>
                        <el-select v-model="valueSelect" placeholder="Seleccionar taller" size="small" class="w-100">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </div>
                </div>
                <div class="row f-14 my-4">
                    <div class="col-6">
                        <p class="input-label-top">Nombre del servicio</p>
                        <el-input v-model="inputNombreServicio" placeholder="Nombre del servicio" size="small" class="w-100" />
                    </div>
                    <div class="col-6">
                        <p class="input-label-top">Tipo de servicio</p>
                        <el-select v-model="valueSelect" placeholder="Seleccionar tipo servicio" size="small" class="w-100">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </div>
                </div>
                <div class="row f-14">
                    <div class="col-12">
                        <p class="input-label-top">Descripción del servicio</p>
                        <el-input v-model="inputNombreServicio" placeholder="Descripción del servicio" type="textarea" :autosize="{ minRows: 4}" class="w-100" />
                    </div>
                </div>
            </div>
            <!-- partials -->
        </modal>
        <modal-crear-orden-compra ref="modalCrearOrdenCompra" />

    </section>
</template>

<script>
export default {
    components:{
        modalCrearOrdenCompra: () => import('./modalCrearOrdenCompra'),
    },
    data(){
        return {
            radio: '3',
            datePicker: '',
            inputNombreServicio: '',
            inputValorCuota: '',
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            valueSelect: ''
        }
    },
    methods: {
        toggle(){
            this.$refs.cambiarEstadoMaterial.toggle()
        },
        continuarCrearOrden (){
            this.toggle()
            this.$refs.modalCrearOrdenCompra.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
